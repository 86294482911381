import React, { Component } from "react"
import { Document, Page } from 'react-pdf'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Resume from "../images/resume.pdf"


class ResumePage extends Component {
    state = {
        numPages: null,
        pageNumber: 1,
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    render() {
        const { pageNumber, numPages } = this.state;

        return (
         <Layout>
         <SEO title="Home Page"></SEO>
         <div className="spacer"/>
            <Document file={ Resume } onLoadSuccess={this.onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} scale='1.4'/>
            </Document>
         </Layout>
        )
    }
}

export default ResumePage